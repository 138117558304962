import React from "react"
import { Link, navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import { routes } from "../utils/routes"
// import "../styles.css"

class IndexPage extends React.Component {
  handleClick(route) {
    navigate(route)
  }

  render() {
    const siteTitle = "sdesingh"

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[`blog`, `sdesingh`, `javascript`, `react`]}
        />

        <p>
          I'm currently a student at Stony Brook University studying Computer
          Science/Applied Mathematics. Interested in distributed systems and the
          cloud but I don't mind doing a little front-end as I like making
          things look pretty 💅 when I can.
        </p>

        <div className="home-nav-container">
          {routes.map(r => (
            <Button
              onClick={e => {
                this.handleClick(r.route)
              }}
            >
              {r.name}
            </Button>
          ))}
        </div>
      </Layout>
    )
  }
}

export default IndexPage
